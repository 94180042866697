<template>
  <div class="citation_box">
    <LEFTNAV></LEFTNAV>
    <div class="box" v-bind:style="{ minHeight: Height + 'px' }">
      <div
        class="box_part"
        v-bind:style="{ minHeight: Height - 40 + 'px' }"
      ></div>
    </div>
  </div>
</template>

<script>
import LEFTNAV from "./component/leftNav.vue";
import {
  confirmUser,
  getUser,
  judge_user,
  user_mes,
  user_article,
  delete_article,
  new_article,
  new_cited,
  article_export,
  article_import,
  auth_task,
  auth_task_post,
  retracker,
  article_detail,
  article_update,
  retracker_get,
} from "@/Api/unquote";
export default {
  data() {
    return {
      Height: 0,
    };
  },
  mounted() {
    //动态设置内容高度 让footer始终居底   header+footer的高度是190
    // console.log(this.name)
    this.Height = document.documentElement.clientHeight - 140; //监听浏览器窗口变化
    window.onresize = () => {
      this.Height = document.documentElement.clientHeight - 140;
    };
  },
  created() {},
  methods: {},
  components: {
    LEFTNAV,
  },
};
</script>

<style   lang="less" scoped>
.citation_box {
  padding-top: 1.25rem;
  min-width: 22.70rem;
  display: flex;
  .box {
    padding: 0.33rem;

    width: 100%;
    .box_part {
      background: white;
      width: 100%;
    }
  }
}
</style>